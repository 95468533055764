<template>
  <div>
    <div class="orgbg">
      <div style="display: flex; align-items: center">
        <span class="orgclass">{{ orgName }}</span>
        <span class="toshowOrg" @click="showOrg">项目概况</span>
      </div>
      <div style="display: flex; align-items: center;cursor: pointer;" @click="choseOrg">
        <img src="~assets/images/screen/tunnel/changeicon.png" alt="" />
        <span style="color: #a0cfff">切换项目部</span>
      </div>
    </div>
    <div class="wall" style="z-index: 8;position: sticky;">
      <div>
        <!-- 监控 -->
        <div class="videoSize" style="width:100%;height:537px">
          <div id="videoBox" style="width:100%;height:537px" v-show="cameraStatus === 0">
            <h5Video v-if="IDchange" :sysUqNo="currentDeviceId"></h5Video>
            <!-- <video id="webrtcPlayer" style="width: 100%;height: 100%;" autoplay controls muted></video> -->
          </div>
          <div class="offline" v-show="cameraStatus">
            <img src="~@/assets/images/5385@2x.png" />
            <p class="topitemFdP">摄像机已离线</p>
          </div>
          <div class="rightTreeTopName"></div>
        </div>
        <!-- 摄像机已离线 -->
      </div>
      <!--暂无图像  -->
      <!-- <div class="imgBox">
          <img src="~@/assets/images/5380@2x.png" class="noImg" />
          <p class="noImgTip">暂无图像</p>
        </div> -->
      <!-- 操控台 -->
      <div class="bottombox">
        <div class="videoControl">
          <div>
            <div class="videoCore">
              <img
                :src="img1"
                @mouseenter="enter"
                @mouseleave="leave"
                @mousedown="handleControlCameraDirection('UP', true)"
                @mouseup="handleControlCameraDirection('UP', false)"
                class="upimg"
              />
              <div>
                <img
                  @mouseenter="enter2"
                  @mouseleave="leave2"
                  @mousedown="handleControlCameraDirection('LEFT', true)"
                  @mouseup="handleControlCameraDirection('LEFT', false)"
                  :src="img2"
                  class="leftimg"
                />
                <img src="~@/assets/images/367.png" class="midimg" />
                <img
                  @mouseenter="enter3"
                  @mouseleave="leave3"
                  @mousedown="handleControlCameraDirection('RIGHT', true)"
                  @mouseup="handleControlCameraDirection('RIGHT', false)"
                  class="rightimg"
                  :src="img3"
                />
              </div>
              <img
                @mouseenter="enter4"
                @mouseleave="leave4"
                @mousedown="handleControlCameraDirection('DOWN', true)"
                @mouseup="handleControlCameraDirection('DOWN', false)"
                :src="img4"
                class="downimg"
              />
            </div>
            <div class="videoOpen">
              <img :src="imgText" style="margin-left: 10px" />
            </div>
          </div>
        </div>
        <div>
          <div class="videozoom">
            <img
              :src="imgEnlarge"
              @mousedown="handleControlCameraZoom('ZOOM_IN', true)"
              @mouseup="handleControlCameraZoom('ZOOM_IN', false)"
            />
            <img
              :src="imgNarrow"
              @mousedown="handleControlCameraZoom('ZOOM_OUT', true)"
              @mouseup="handleControlCameraZoom('ZOOM_OUT', false)"
            />
          </div>
        </div>
        <!-- 切换监控点 -->
        <div class="leftright">
          <img :src="imgleft" alt="上一页" @click="prePage" />
          <div>
            <div
              :class="item.webrtcUrl ? 'tipbg' : 'tipbgnot'"
              v-for="item in cloneWebrtc"
              @click="item.webrtcUrl ? changeUrl(item) : ''"
            >
              <p :class="item.choose ? 'chosetipbg' : ''">{{ item.deviceName }}</p>
            </div>
            <p class="pagecss">共{{ totalPage }}页，{{ pageNo }}/{{ totalPage }}</p>
          </div>
          <img :src="imgRight" alt="下一页" @click="nextPage" />
        </div>
      </div>
    </div>
    <div style="z-index: 8;" class="infodialog" v-if="infoVisible">
      <img @click="reset" class="orginfoclose" src="~assets/images/screen/tunnel/orginfoclose.png" alt="" />
      <p>{{ dialogTitle }}</p>
      <img class="infoPb" src="~assets/images/screen/tunnel/infoPb.png" alt="" />
      <div v-if="dialogTitle === '项目概况'" class="infotext">
        <el-skeleton :rows="6" animated :loading="!orgInfo.length" />
        {{ orgInfo }}
      </div>
      <div v-else class="orgList_box">
        <div class="orgItem" v-for="item in orgList" :key="item.orgId">
          <div :class="item.isChoose ? 'divchose' : ''" @click="getOrgName(item)">
            <span style="cursor: pointer;">{{ item.orgName }}</span
            ><img v-if="item.isChoose" src="~assets/images/screen/tunnel/ischose.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTunnelDepartment, getTunnelCamera, toPlay, toStop } from '@/api/general-user/sceen';
import { cameraDirectionControl } from '@/api/general-user/video-monitor.js';
import h5Video from '@/components/video/h5Video.vue';
export default {
  components: {
    h5Video
  },
  props: {
    orgInfo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      img1: require('@/assets/images/5611(1).png'),
      img2: require('@/assets/images/5611.png'),
      img3: require('@/assets/images/5612.png'),
      img4: require('@/assets/images/5612(1).png'),
      imgText: require('@/assets/images/screen/tunnel/PTZtext.png'),
      imgleft: require('@/assets/images/screen/tunnel/left.png'),
      imgRight: require('@/assets/images/screen/tunnel/right.png'),
      imgEnlarge: require('@/assets/images/screen/tunnel/zoom.png'),
      imgNarrow: require('@/assets/images/screen/tunnel/zoomout.png'),
      imgoffline: require('@/assets/images/5385@2x.png'),
      dialogVisible: false,
      webrtcList: [],
      cloneWebrtc: [],
      videoUrl: '',
      currentDeviceId: '', //线上ID1
      cameraStatus: 0,
      orgList: [],
      ruleForm: { orgId: '' },
      org: {},
      orgName: '',
      loading: false,
      pageNo: 1,
      player: {},
      playToStop: {
        app: 'live',
        stream: ''
      },
      infoVisible: true,
      dialogTitle: '项目概况',
      agreeType: '',
      hlsVideoData: [],
      IDchange: false
    };
  },
  created() {
    this.getTunnelDepartment();
    // window.addEventListener('beforeunload', e => this.pageClose());
  },
  mounted() {
    // this.getvideo();
  },
  methods: {
    /* 页面关闭回调 */
    pageClose() {
      if (this.videoData.length) {
        this.videoData.forEach(item => {
          this.toStop(item.urlParams.stream);
          item.destroy();
        });
      }
    },
    /* 弹窗显示 */
    choseOrg() {
      this.dialogTitle = '项目部切换';
      this.infoVisible = true;
    },
    showOrg() {
      this.dialogTitle = '项目概况';
      this.infoVisible = true;
    },
    /* 切换监控 */
    changeUrl(val) {
      // this.toStop();
      this.IDchange = false;
      this.webrtcList.forEach(item => {
        item.choose = false;
      });
      val.choose = true;
      this.$forceUpdate();
      var { webrtcUrl, hlsUrl, agreeType, deviceId, cameraStatus } = val;
      this.videoUrl = agreeType ? hlsUrl : webrtcUrl;
      this.agreeType = agreeType;
      this.currentDeviceId = deviceId;
      this.cameraStatus = cameraStatus;
      this.$nextTick(() => {
        if (this.currentDeviceId) {
          this.IDchange = true;
        }
      });
    },
    /* 设置视频流 */
    getvideo() {
      //TODO:销毁？
      /* if(this.player.options){
        this.player.destroy();
        console.log("销毁");
      } */

      if (this.cameraStatus === 0) {
        // var video = document.getElementById('webrtcPlayer');
        /* if (this.agreeType) {
          if (video) {
            //my-player为页面video元素的id
            video.classList.add('video-js');
            let childNode = document.createElement('source');
            childNode.src = this.videoUrl;
            video.appendChild(childNode);
            var player = this.$video(video);
            //播放
            player.play();
            this.hlsVideoData.push(player);
          }
        } else {
          var option = {
            video: video,
            autoplay: true
          };
          this.player = new JSWebrtc.Player(this.videoUrl, option);
          this.$nextTick(() => {
            this.toPlay();
          });
        } */
      }
    },
    /* 播放回调 */
    async toPlay() {
      var streamArr = this.videoUrl.split('/');
      this.playToStop.stream = streamArr[streamArr.length - 1];
      await toPlay(this.playToStop).then(res => {});
    },
    /* 停止回调 */
    async toStop() {
      if (this.hlsVideoData.length) {
        this.hlsVideoData.forEach(item => {
          item.dispose();
        });
        this.hlsVideoData = [];
        this.$nextTick(() => {
          let videoBox = document.getElementById('videoBox');
          if (videoBox) {
            videoBox.innerHTML =
              "<video id='webrtcPlayer' style='width: 100%;height: 100%;' autoplay controls muted></video>";
          }
        });
      } else {
        var streamArr = this.videoUrl.split('/');
        this.playToStop.stream = streamArr[streamArr.length - 1];
        toStop(this.playToStop).then(res => {});
      }
    },
    /* 获取机构 */
    getTunnelDepartment() {
      getTunnelDepartment().then(res => {
        res.data.forEach(item => {
          item.isChoose = false;
        });
        res.data[0].isChoose = true;
        this.orgList = res.data;
        var { orgName, orgId } = this.orgList[0];
        this.orgName = orgName;
        this.ruleForm.orgId = orgId;
        this.$emit('getOrgId', this.orgList[0]); //获取信息
        this.getTunnelCamera();
      });
    },
    /* 机构下摄像头 */
    getTunnelCamera() {
      this.pageNo = 1;
      this.loading = true;
      getTunnelCamera({ orgId: this.ruleForm.orgId })
        .then(res => {
          this.IDchange = false;

          this.webrtcList = res.data;
          var { webrtcUrl, hlsUrl, agreeType, deviceId, cameraStatus } = this.webrtcList[0];
          this.videoUrl = agreeType ? hlsUrl : webrtcUrl;
          this.agreeType = agreeType;
          this.currentDeviceId = deviceId;
          this.cameraStatus = cameraStatus;
          this.pageQuery();
          this.loading = false;
          this.webrtcList.forEach(item => {
            item.choose = false;
          });
          this.webrtcList[0].choose = true;
          this.$nextTick(() => {
            if (this.currentDeviceId) {
              this.IDchange = true;
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      this.infoVisible = false;
      this.ruleForm.orgId = '';
    },
    handlesure() {
      this.getTunnelCamera();
      this.$emit('getOrgId', this.org); //获取信息
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.orgName = this.org.orgName;
      });
    },
    getOrgName(val) {
      this.orgList.forEach(item => {
        item.isChoose = item.orgId === val.orgId;
      });
      this.org = val;
      this.ruleForm.orgId = val.orgId;
      this.handlesure();
    },
    /* 分页 */
    pageQuery() {
      this.cloneWebrtc = this.webrtcList.slice((this.pageNo - 1) * 8, (this.pageNo - 1) * 8 + 8);
      while (this.cloneWebrtc.length < 8) {
        this.cloneWebrtc.push({ deviceName: '——', webrtcUrl: '' });
      }
    },
    prePage() {
      if (this.pageNo > 1) {
        this.pageNo = this.pageNo - 1;
        this.pageQuery();
      }
    },
    nextPage() {
      if (this.pageNo < this.totalPage) {
        this.pageNo = this.pageNo + 1;
        this.pageQuery();
      }
    },
    enter() {
      this.img1 = require('@/assets/images/56111(1).png');
    },
    enter2() {
      this.img2 = require('@/assets/images/56111.png');
    },
    enter3() {
      this.img3 = require('@/assets/images/56121.png');
    },
    enter4() {
      this.img4 = require('@/assets/images/56121(1).png');
    },
    leave() {
      this.img1 = require('@/assets/images/5611(1).png');
    },
    leave2() {
      this.img2 = require('@/assets/images/5611.png');
    },
    leave3() {
      this.img3 = require('@/assets/images/5612.png');
    },
    leave4() {
      this.img4 = require('@/assets/images/5612(1).png');
    },
    enter5() {
      this.imgEnlarge = require('@/assets/images/54571.png');
    },
    leave5() {
      this.imgEnlarge = require('@/assets/images/screen/tunnel/zoom.png');
    },
    enter6() {
      this.imgNarrow = require('@/assets/images/54601.png');
    },
    leave6() {
      this.imgNarrow = require('@/assets/images/screen/tunnel/zoomout.png');
    },
    // 云台控制
    // 按下方向键  operate:  方向控制 left-向左 right-向右 up-向上 down-向下 stop(停止)
    handleControlCameraDirection(operate, bool) {
      cameraDirectionControl({
        deviceId: this.currentDeviceId,
        action: bool ? '0' : '1',
        command: operate
      })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
          }
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        });
    },
    //zoomin-放大 zoomout-缩小
    handleControlCameraZoom(operate, bool) {
      cameraDirectionControl({
        deviceId: this.currentDeviceId,
        action: bool ? '0' : '1',
        command: operate
      })
        .then(res => {
          if (!res.success) {
            this.$message.error(res.message);
          }
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        });
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.webrtcList.length / 8);
    }
  },
  beforeDestroy() {
    //TODO://销毁视频流
    // this.getvideo("detroy");
    // this.toStop();
  },
  watch: {
    'org.orgName'(val, old) {
      if (!this.dialogVisible) {
        this.orgName = val;
      } //防止选择时就更改机构名
    }
  },
  orgInfo(val, old) {
    this.loading = val || '';
  }
};
</script>
<style lang="less" scoped>
#webrtcPlayer {
  width: 100%;
  object-fit: none;
  display: block;
  height: calc(100vh - 400px);
}
.offline {
  width: 100%;
  object-fit: none;
  display: block;
  height: calc(100vh - 400px);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
}
.imgBox {
  position: relative;
  .noImg {
    width: 100%;
    object-fit: none;
    display: block;
    height: 700px;
  }
  .noImgTip {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.orgbg {
  background-image: url('~@/assets/images/screen/tunnel/tip.png');
  background-repeat: no-repeat;
  background-size: 100%;
  // width: 318px;
  font-size: 18px;
  color: #ffffff;
  padding: 5px 10px;
  margin-top: 21px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.videoCore {
  background-image: url('~@/assets/images/screen/tunnel/PTZ.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 130px;
  height: 130px;
  position: relative;
}
.bottombox {
  display: flex;
  margin-top: 15px;
}
.leftright {
  display: flex;
  width: 100%;
  padding: 0 25px;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 77%;
    margin: 0 25px;
    > .tipbg {
      background-image: url('~@/assets/images/screen/tunnel/bottomtip.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 23%;
      margin: 9px 6px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      cursor: pointer;
      > p {
        font-size: 18px;
        color: #ffffff;
        margin: 0;
        width: 125px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      > p:hover {
        color: cadetblue;
      }
      .chosetipbg {
        color: #67d0f2;
      }
    }
  }
}
.pagecss {
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #67d0f2;
  margin: 0;
  width: 100%;
}
.videoOpen {
  margin-top: 20px;
}
.videoControl {
  margin-left: 30px;
}
.tipbgnot {
  background-image: url('~@/assets/images/screen/tunnel/Cbottomtip.png');
  background-repeat: no-repeat;
  width: 23%;
  margin: 9px 6px;
  text-align: center;
  height: 55px;
  cursor: pointer;
  visibility: hidden;
  // visibility: hidden;
  // display: none;
}
.upimg {
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  cursor: pointer;
}
.downimg {
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translateX(-50%);
  cursor: pointer;
}
.leftimg {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.rightimg {
  position: absolute;
  left: 65%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.midimg {
  position: absolute;
  left: 50%;
  top: 49.5%;
  transform: translate(-50%, -50%);
}
.videozoom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  margin-top: 10px;
  margin-left: 25px;
  cursor: pointer;
  > img {
    border-radius: 50%;
  }
  > img:hover {
    background-color: #85ce61;
  }
}
.orgclass {
  max-width: 300px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.infodialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 500px;
  background-image: url('~@/assets/images/screen/tunnel/orginfo.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 22px;
  text-align: center;
  .orginfoclose {
    position: absolute;
    right: 1px;
    top: 31px;
    cursor: pointer;
  }
  .infoPb {
    height: 4px;
    width: 342px;
  }
  .infotext {
    width: 495px;
    height: 360px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 22px;
    margin-top: 30px;
    overflow-y: scroll;
    /deep/ .el-skeleton.is-animated .el-skeleton__item {
      background: #a5a5a5;
    }
  }
  .infotext::-webkit-scrollbar {
    width: 6px !important;
  }
  .infotext::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }
  .orgList_box {
    width: 495px;
    height: 360px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    overflow-y: scroll;
    margin-top: 25px;
    > div {
      padding: 5px;
    }
    .divchose {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(90deg, rgba(17, 123, 247, 0), rgba(17, 123, 247, 0.48) 48%, rgba(17, 123, 247, 0));
    }
  }
  .orgList_box::-webkit-scrollbar {
    width: 6px !important;
  }
  .orgList_box::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }
}
.toshowOrg {
  margin-left: 20px;
  font-size: 18px;
  color: #a0cfff;
  border-bottom: 1px solid #a0cfff;
  cursor: pointer;
}
</style>
