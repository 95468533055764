<template>
  <div class="bg">
    <div class="trip">
      <div class="leftview">
        <div class="H1title"><p>左洞信息</p></div>
        <div>
          <div class="monittip">
            <p>气体监测</p>
          </div>
          <div class="gas">
            <div v-for="(item, index) in gasList.left" :key="index">
              <p>{{ item.val }}</p>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="monittip">
            <p>安全步距</p>
          </div>
          <div class="step">
            <div v-for="(item, index) in stepList.left" :key="index">
              <p>{{ item.name }}</p>
              <p>{{ item.value }}</p>
            </div>
          </div>
        </div>
        <div>
          <div class="monittip">
            <p>沉降预警</p>
          </div>
          <div class="settlement">
            <div v-for="(item, index) in settlementList.left" :key="index">
              <div>
                <p :style="item.value === '正常' ? 'color:#36C13A;font-size: 28px;' : ''">{{ item.value }}</p>
                <span
                  v-if="item.change != 0"
                  :class="item.change > 0 ? 'upicon' : item.change == 0 ? 'el-icon-minus' : 'downicon'"
                ></span>
                <p v-if="item.change != 0">{{ item.change }}</p>
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div class="monittip">
            <p>超前地质预警</p>
          </div>
          <div class="Geological">
            <div>
              <div class="geobg">
                <div></div>
                <p>{{ geologicalList.left.info }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="midview">
        <MidView @getOrgId="getOrgId" :orgInfo='orgInfo'></MidView>
      </div>
      <div class="rightview">
        <div class="H1title"><p>右洞信息</p></div>
        <div>
          <div class="monittip">
            <p>气体监测</p>
          </div>
          <div class="gas">
            <div v-for="(item, index) in gasList.right" :key="index">
              <p>{{ item.val }}</p>
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div>
            <div class="monittip">
              <p>安全步距</p>
            </div>
            <div class="step">
              <div v-for="(item, index) in stepList.right" :key="index">
                <p>{{ item.name }}</p>
                <p>{{ item.value }}</p>
              </div>
            </div>
          </div>
          <div>
            <div class="monittip">
              <p>沉降预警</p>
            </div>
            <div class="settlement">
              <div v-for="(item, index) in settlementList.right" :key="index">
                <div>
                  <p :style="item.value === '正常' ? 'color:#36C13A;font-size: 28px;' : ''">{{ item.value }}</p>
                  <span
                    v-if="item.change != 0"
                    :class="item.change > 0 ? 'upicon' : item.change == 0 ? 'el-icon-minus' : 'downicon'"
                  ></span>
                  <p v-if="item.change != 0">{{ item.change }}</p>
                </div>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
          <div class="monittip">
            <p>超前地质预警</p>
          </div>
          <div class="Geological">
            <div>
              <div class="geobg">
                <div></div>
                <p>{{ geologicalList.right.info }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
  </div>
</template>
<script>
import MidView from '../component/midview/monitoning.vue';
import { getTunnelData } from '@/api/general-user/sceen';
export default {
  components: { MidView },
  data() {
    return {
      orgId: '', //项目部ID
      gasList: {
        left: [
          /* { name: '一氧化碳', val: '1.25ppm' },
          { name: '二氧化碳', val: '0.01%' },
          { name: '硫化氢', val: '0ppm' },
          { name: '瓦斯', val: '0.01%' },
          { name: '氧气', val: '0.0004%' },
          { name: '风速', val: '0.54m/s' },
          { name: '风筒', val: '关' },
          { name: '馈点', val: '关' },
          { name: '三态开停', val: '关' } */
        ],
        right: [
          /* { name: '一氧化碳', val: '1.25ppm' },
          { name: '二氧化碳', val: '0.01%' },
          { name: '硫化氢', val: '0ppm' },
          { name: '瓦斯', val: '0.01%' },
          { name: '氧气', val: '0.0004%' },
          { name: '风速', val: '0.54m/s' },
          { name: '风筒', val: '关' },
          { name: '馈点', val: '关' },
          { name: '三态开停', val: '开' } */
        ]
      },
      stepList: {
        left: [
          { name: '二衬—掌子面距离', value: '' },
          { name: '仰拱—掌子面距离', value: '' },
          { name: '现围岩等级', value: '' }
        ],
        right: [
          { name: '二衬—掌子面距离', value: '' },
          { name: '仰拱—掌子面距离', value: '' },
          { name: '现围岩等级', value: '' }
        ]
      },
      settlementList: {
        left: [
          { name: '拱顶沉降值', value: '', change: '0' },
          { name: '拱腰收敛情况', value: '', change: '0' }
        ],
        right: [
          { name: '拱顶沉降值', value: '', change: '0' },
          { name: '拱腰收敛情况', value: '', change: '0' }
        ]
      },
      geologicalList: {
        left: {
          info: ''
        },
        right: {
          info: ''
        }
      },
      orgInfo:'',
    };
  },
  methods: {
    getTunnelData() {
      getTunnelData({ id: this.orgId }).then(res => {
          var {left,right}=res.data;
          this.gasList.left = left.gasList;
          this.gasList.right = right.gasList;

          this.stepList.left[0].value = left.secLine;
          this.stepList.left[1].value = left.invertedArch;
          this.stepList.left[2].value = left.level;
          this.stepList.right[0].value = left.secLine;
          this.stepList.right[1].value = left.invertedArch;
          this.stepList.right[2].value = left.level;

          this.settlementList.left[0].value = left.lowerData.vault;
          this.settlementList.right[0].value = right.lowerData.vault;
          this.settlementList.left[1].value = left.lowerData.archWaist;
          this.settlementList.right[1].value = right.lowerData.archWaist;

          this.geologicalList.left.info = left.lowerData.warning;
          this.geologicalList.right.info = right.lowerData.warning;

          this.orgInfo = left.lowerData.projectInfo;
      });
    },
    getOrgId(val) {
      this.orgId = val.orgId;
    }
  },
  mounted() {
    // this.getTunnelData();
  },
  watch: {
    orgId() {
      this.getTunnelData();
    }
  }
};
</script>
<style lang="less" scoped>
.H1title {
  background-image: url('~@/assets/images/screen/tunnel/titleBg.png');
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  width: 175px;
  background-size: 100%;
  p {
    font-weight: bolder;
    font-size: 18px;
    text-align: center;
    color: #19ecff;
    margin: 0;
    margin-top: 5px;
  }
}
.monittip {
  width: 150px;
  padding: 10px 10px;
  background-image: url('~@/assets/images/screen/tunnel/tip.png');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 20px;
  margin-top: 20px;
  p {
    font-size: 14px;
    text-align: left;
    color: #ffffff;
    margin: 0;
    font-weight: bold;
  }
}
.trip {
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  .leftview {
    position: absolute;
    width: 20%;
    height: calc(100vh - 150px);
    margin-top: 120px;
    margin-left: 20px;
    padding-top: 20px;
    z-index: 10;
    background-image: url('~@/assets/images/screen/tunnel/dataView.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .midview {
    width: 56.3%;
    height: calc(100vh - 110px);
    margin-left: 22%;
    margin-top: 100px;
  }
  .rightview {
    position: absolute;
    width: 20%;
    height: calc(100vh - 150px);
    right: 0;
    margin-top: 120px;
    margin-right: 20px;
    padding-top: 20px;
    z-index: 10;
    background-image: url('~@/assets/images/screen/tunnel/dataView.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.bg {
  background-image: url('~@/assets/images/screen/tunnel/bg.png');
}
.gas::-webkit-scrollbar {
  width: 6px !important;
}
.gas::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
.gas {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-top: 20px;
  height: 145px;
  overflow-y: scroll;
  // IE 10+
  -ms-overflow-style: none;
  // Firefox
  overflow: -moz-scrollbars-none;
  div {
    width: 30%;
    text-align: center;
    background-image: url('~@/assets/images/screen/tunnel/infobg.png');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    margin-bottom: 10px;
    p {
      margin: 0;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    p:nth-child(1) {
      font-size: 18px;
      text-align: center;
      color: #fceb1a;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-line-clamp: 1;
      margin-bottom: 2px;
    }
  }
}
.step {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  div {
    background-image: url('~@/assets/images/screen/tunnel/stepinfobg.png');
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-top: 10px;
    p {
      font-size: 14px;
      text-align: left;
      color: #ffffff;
    }
    p:nth-child(2) {
      font-size: 18px;
      text-align: right;
      color: #fceb1a;
    }
  }
  div:nth-last-child(1) {
    p:nth-child(2) {
      color: #f97b1c;
    }
  }
}
.settlement {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  > div {
    width: 35%;
    background-image: url('~@/assets/images/screen/tunnel/settlement.png');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    div {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      p {
        margin: 0;
        font-size: 36px;
      }
      p:nth-child(1) {
        color: #fedb65;
      }
      /* p:nth-last-child(1) {
        font-size: 14px;
        color: #ed1b1b;
      } */
      span {
        width: 10px;
        height: 20px;
        margin-left: 5px;
      }
      .el-icon-minus {
        color: aquamarine;
      }
    }
    > p {
      font-size: 14px;
      color: #ffffff;
      margin: 0;
      margin-top: 5px;
    }
  }
}
.upicon {
  background-image: url('~@/assets/images/screen/tunnel/upicon.png');
  background-repeat: no-repeat;
}
.Geological {
  padding: 0 25px;
  font-size: 14px;
  color: #ffffff;
  > div {
    > div {
      display: flex;
      justify-content: space-between;
    }
    .geobg {
      align-items: center;
      > div {
        background-image: url('~@/assets/images/screen/tunnel/geobg.png');
        background-repeat: no-repeat;
        width: 102px;
        height: 72px;
        background-size: 100% 100%;
      }
      > p {
        font-size: 12px;
        width: 220px;
        height: calc(100vh - 800px);
        overflow-y: scroll;
        margin: 0;
      }
      > p::-webkit-scrollbar {
        width: 6px !important;
      }
      > p::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 5px;
      }
    }
  }
}
</style>
